.wallet-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .wallet-frame424 {
    /* gap: 48px; */
    width: 100%;
    height: auto;
    display: flex;
    padding: 20px;
    flex-grow: 1;
    align-self: stretch;
    align-items: flex-start;
    border-radius: 4px;
    flex-direction: column;
    background-color: var(--dl-color-shades-white);
  }
  .wallet-text {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 22.5px;
  }
  .wallet-frame467 {
    gap: 24px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .wallet-frame281 {
    gap: 16px;
    width: 467px;
    height: 186px;
    display: flex;
    padding: 16px 0;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.029999999329447746) ;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
  }
  .wallet-frame474 {
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wallet-money-wallet {
    width: 24px;
    height: 24px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .wallet-group {
    top: -5.000000058430487e-8px;
    left: -5.000000058430487e-8px;
    width: 24px;
    height: 24px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .wallet-group1 {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .wallet-path {
    top: 13.230003356933594px;
    left: 13px;
    width: 1px;
    height: 1px;
    position: absolute;
  }
  .wallet-path1 {
    top: 3px;
    left: 3px;
    width: 18px;
    height: 15px;
    position: absolute;
  }
  .wallet-path2 {
    top: 8.5px;
    left: 17px;
    width: 4px;
    height: 1px;
    position: absolute;
  }
  .wallet-path3 {
    top: 4.2826690673828125px;
    left: 3px;
    width: 14px;
    height: 17px;
    position: absolute;
  }
  .wallet-text02 {
    color: var(--dl-color-slate-800);
    height: auto;
    text-align: left;
    line-height: 20px;
  }
  .wallet-text04 {
    color: var(--dl-color-slate-800);
    height: auto;
    text-align: left;
    line-height: 50px;
  }
  .wallet-frame2811 {
    gap: 16px;
    display: flex;
    overflow: hidden;
    align-self: stretch;
    align-items: flex-start;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
  }
  .wallet-frame295 {
    gap: 16px;
    display: flex;
    padding: 16px 24px;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
  }
  .wallet-frame278 {
    gap: 16px;
    display: flex;
    align-items: center;
  }
  .wallet-text06 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 20px;
  }
  .wallet-frame276 {
    width: 294px;
    display: flex;
    padding: 8px;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(203, 213, 225, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
  }
  .wallet-text08 {
    color: var(--dl-color-slate-400);
    height: auto;
    flex-grow: 1;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-arrows-diagrams-arrow {
    width: 24px;
    height: 24px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .wallet-group2 {
    top: -5.000000058430487e-8px;
    left: -5.000000058430487e-8px;
    width: 24px;
    height: 24px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .wallet-path4 {
    top: 10px;
    left: 8px;
    width: 8px;
    height: 4px;
    position: absolute;
  }
  .wallet-frame473 {
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .wallet-frame380 {
    gap: 16px;
    display: flex;
    padding: 24px 48px;
    align-self: stretch;
    margin: 10px;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(203, 213, 225, 1);
    border-style: solid;
    border-width: 1px 0;
    justify-content: space-between;
    background-color: var(--dl-color-slate-50);
  }
  .wallet-text10 {
    color: var(--dl-color-slate-500);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text12 {
    color: var(--dl-color-slate-500);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text14 {
    color: var(--dl-color-slate-500);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text16 {
    color: var(--dl-color-slate-500);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-frame381 {
    gap: 16px;
    display: flex;
    padding: 24px 48px;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 0 0 1px;
    justify-content: space-between;
  }
  .wallet-text18 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text20 {
    color: var(--dl-color-lime-600);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text22 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text24 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-frame382 {
    gap: 16px;
    display: flex;
    padding: 24px 48px;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 0 0 1px;
    justify-content: space-between;
  }
  .wallet-text26 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text28 {
    color: var(--dl-color-lime-600);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text30 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text32 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-frame385 {
    gap: 16px;
    display: flex;
    padding: 24px 48px;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 0 0 1px;
    justify-content: space-between;
  }
  .wallet-text34 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text36 {
    color: var(--dl-color-lime-600);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text38 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text40 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-frame383 {
    gap: 16px;
    display: flex;
    padding: 24px 48px;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 0 0 1px;
    justify-content: space-between;
  }
  .wallet-text42 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text44 {
    color: var(--dl-color-red-600);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text46 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text48 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-frame384 {
    gap: 16px;
    display: flex;
    padding: 24px 48px;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 0 0 1px;
    justify-content: space-between;
  }
  .wallet-text50 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text52 {
    color: var(--dl-color-red-600);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text54 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .wallet-text56 {
    color: var(--dl-color-slate-900);
    width: 180px;
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  
  /* Inside wallet.css */

.green {
  background-color: lightgreen;
}

.red {
  background-color: lightcoral;
}

.track-order-container {
  display: flex;
  justify-content: center;
  /* align-items: center;
  min-height: 100vh; */
}

.track-order-frame215 {
  background-color: #ffffff;
  margin: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 20px;
  width: 90%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.track-order-frame424 {
  gap: 16px;
  height: 323px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  flex-direction: column;
  background-color: var(--dl-color-shades-white);
}

.track-order-frame510 {
  gap: 16px;
  display: flex;
  padding: 24px 48px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  text-align: center;
}

.track-order-text {
  color: var(--dl-color-slate-900);
  height: auto;
  text-align: center;
  line-height: 30px;
  font-size: 24px;
  font-weight: bold;
}

.track-order-frame259 {
  gap: 16px;
  display: flex;
  padding: 0 48px;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.track-order-frame107 {
  gap: 4px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.track-order-text02 {
  color: var(--dl-color-slate-900);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 25px;
  font-size: 20px;
  font-weight: medium;
}

.track-order-text04 {
  color: var(--dl-color-slate-600);
  height: auto;
  align-self: stretch;
  text-align: left;
  line-height: 20px;
  font-size: 16px;
}

.track-order-frame106 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.track-order-input {
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 16px;
}

.track-order-frame12 {
  padding: 12px 24px;
  background-color: #031B89;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: medium;
  cursor: pointer;
  width: 100%;
}
.track-order-frame123 {
  /* padding: 12px 24px; */
  /* background-color: #031B89; */
  /* color: #ffffff; */
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: medium;
  cursor: pointer;
  width: 100%;
}

.track-order-text10 {
  text-align: center;
  font-size: 14px;
  color: #3182ce;
  text-decoration: underline;
  cursor: pointer;
}
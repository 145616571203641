.verifying-prescription-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .verifying-prescription-frame215 {
    gap: 20px;
    width: 100%;
    height: auto;
    display: flex;
    padding: 48px 100px;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
    justify-content: center;
    background-color: var(--dl-color-shades-offwhite);
  }
  .verifying-prescription-frame332 {
    gap: 16px;
    width: 1240px;
    display: flex;
    padding: 48px 0;
    flex-grow: 1;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
  }
  .verifying-prescription-frame383 {
    gap: 16px;
    display: flex;
    padding: 0 0 32px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .verifying-prescription-readingglassesbro {
    width: 241px;
    height: 149.9981689453125px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .verifying-prescription-backgroundsimple {
    top: 0px;
    left: 0px;
    width: 241.10255432128906px;
    height: 148.26443481445312px;
    display: flex;
    opacity: 0.00;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .verifying-prescription-mug {
    top: 26.11513900756836px;
    left: 15.523040771484375px;
    width: 56.67949295043945px;
    height: 70.675537109375px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .verifying-prescription-vector {
    top: 9.88293743133545px;
    left: 0.14146167039871216px;
    width: 56px;
    height: 61px;
    position: absolute;
  }
  .verifying-prescription-vector001 {
    top: 9.704657554626465px;
    left: 0px;
    width: 57px;
    height: 61px;
    position: absolute;
  }
  .verifying-prescription-vector002 {
    top: 0.17052912712097168px;
    left: 10.481243133544922px;
    width: 46px;
    height: 19px;
    position: absolute;
  }
  .verifying-prescription-vector003 {
    top: 0px;
    left: 10.310229301452637px;
    width: 46px;
    height: 20px;
    position: absolute;
  }
  .verifying-prescription-vector004 {
    top: 1.240211844444275px;
    left: 13.017863273620605px;
    width: 41px;
    height: 17px;
    position: absolute;
  }
  .verifying-prescription-vector005 {
    top: 1.0696827173233032px;
    left: 12.847334861755371px;
    width: 41px;
    height: 18px;
    position: absolute;
  }
  .verifying-prescription-vector006 {
    top: 13.533811569213867px;
    left: 19.105560302734375px;
    width: 29px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector007 {
    top: 13.363282203674316px;
    left: 18.670516967773438px;
    width: 30px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-books2 {
    top: 4.695385932922363px;
    left: 112.13645935058594px;
    width: 112.5971908569336px;
    height: 84.56873321533203px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .verifying-prescription-vector008 {
    top: 20.672780990600586px;
    left: 11.685120582580566px;
    width: 101px;
    height: 64px;
    position: absolute;
  }
  .verifying-prescription-vector009 {
    top: 20.50225257873535px;
    left: 11.5228271484375px;
    width: 101px;
    height: 64px;
    position: absolute;
  }
  .verifying-prescription-vector010 {
    top: 59.6541862487793px;
    left: 29.415790557861328px;
    width: 80px;
    height: 24px;
    position: absolute;
  }
  .verifying-prescription-vector011 {
    top: 59.46815490722656px;
    left: 29.2627010345459px;
    width: 80px;
    height: 24px;
    position: absolute;
  }
  .verifying-prescription-vector012 {
    top: 0.17052912712097168px;
    left: 0.16713792085647583px;
    width: 112px;
    height: 67px;
    position: absolute;
  }
  .verifying-prescription-vector013 {
    top: 0px;
    left: 0px;
    width: 112px;
    height: 67px;
    position: absolute;
  }
  .verifying-prescription-vector014 {
    top: 7.557540416717529px;
    left: 0.7654432654380798px;
    width: 110px;
    height: 57px;
    position: absolute;
  }
  .verifying-prescription-vector015 {
    top: 6.712646484375px;
    left: 0.6002431511878967px;
    width: 110px;
    height: 58px;
    position: absolute;
  }
  .verifying-prescription-vector016 {
    top: 44.2057991027832px;
    left: 0.7654432654380798px;
    width: 90px;
    height: 21px;
    position: absolute;
  }
  .verifying-prescription-vector017 {
    top: 44.04302215576172px;
    left: 0.6002431511878967px;
    width: 90px;
    height: 21px;
    position: absolute;
  }
  .verifying-prescription-book1 {
    top: 63.41310119628906px;
    left: 41.26850891113281px;
    width: 164.66952514648438px;
    height: 86.58507537841797px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .verifying-prescription-vector018 {
    top: 22.610610961914062px;
    left: 86.74506378173828px;
    width: 78px;
    height: 64px;
    position: absolute;
  }
  .verifying-prescription-vector019 {
    top: 22.401325225830078px;
    left: 86.57260131835938px;
    width: 78px;
    height: 64px;
    position: absolute;
  }
  .verifying-prescription-vector020 {
    top: 70.76958465576172px;
    left: 87.01878356933594px;
    width: 77px;
    height: 16px;
    position: absolute;
  }
  .verifying-prescription-vector021 {
    top: 70.59130096435547px;
    left: 86.85115814208984px;
    width: 78px;
    height: 16px;
    position: absolute;
  }
  .verifying-prescription-vector022 {
    top: 21.161113739013672px;
    left: 0.3236177861690521px;
    width: 87px;
    height: 64px;
    position: absolute;
  }
  .verifying-prescription-vector023 {
    top: 20.998336791992188px;
    left: 0.14775961637496948px;
    width: 87px;
    height: 65px;
    position: absolute;
  }
  .verifying-prescription-vector024 {
    top: 72.04080200195312px;
    left: 0.4083978831768036px;
    width: 86px;
    height: 13px;
    position: absolute;
  }
  .verifying-prescription-vector025 {
    top: 71.25791931152344px;
    left: 0px;
    width: 87px;
    height: 14px;
    position: absolute;
  }
  .verifying-prescription-vector026 {
    top: 20.254209518432617px;
    left: 3.4604814052581787px;
    width: 84px;
    height: 63px;
    position: absolute;
  }
  .verifying-prescription-vector027 {
    top: 20.075929641723633px;
    left: 3.253133535385132px;
    width: 84px;
    height: 63px;
    position: absolute;
  }
  .verifying-prescription-vector028 {
    top: 69.79291534423828px;
    left: 3.4604814052581787px;
    width: 84px;
    height: 14px;
    position: absolute;
  }
  .verifying-prescription-vector029 {
    top: 69.62239074707031px;
    left: 3.075822114944458px;
    width: 85px;
    height: 14px;
    position: absolute;
  }
  .verifying-prescription-vector030 {
    top: 57.63884353637695px;
    left: 19.183557510375977px;
    width: 68px;
    height: 15px;
    position: absolute;
  }
  .verifying-prescription-vector031 {
    top: 57.4605598449707px;
    left: 18.885616302490234px;
    width: 69px;
    height: 16px;
    position: absolute;
  }
  .verifying-prescription-vector032 {
    top: 13.634578704833984px;
    left: 8.744946479797363px;
    width: 151px;
    height: 71px;
    position: absolute;
  }
  .verifying-prescription-vector033 {
    top: 13.479552268981934px;
    left: 8.544381141662598px;
    width: 152px;
    height: 72px;
    position: absolute;
  }
  .verifying-prescription-vector034 {
    top: 13.642330169677734px;
    left: 8.744946479797363px;
    width: 15px;
    height: 62px;
    position: absolute;
  }
  .verifying-prescription-vector035 {
    top: 13.464049339294434px;
    left: 8.527424812316895px;
    width: 15px;
    height: 62px;
    position: absolute;
  }
  .verifying-prescription-vector036 {
    top: 4.68179988861084px;
    left: 85.90404510498047px;
    width: 71px;
    height: 79px;
    position: absolute;
  }
  .verifying-prescription-vector037 {
    top: 4.519021987915039px;
    left: 85.73690795898438px;
    width: 72px;
    height: 79px;
    position: absolute;
  }
  .verifying-prescription-vector038 {
    top: 22.688125610351562px;
    left: 118.62190246582031px;
    width: 23px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector039 {
    top: 25.36233139038086px;
    left: 118.84281158447266px;
    width: 23px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector040 {
    top: 28.03653907775879px;
    left: 119.06033325195312px;
    width: 23px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector041 {
    top: 30.718496322631836px;
    left: 119.28125px;
    width: 23px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector042 {
    top: 33.41595458984375px;
    left: 119.49537658691406px;
    width: 24px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector043 {
    top: 36.07465744018555px;
    left: 119.71289825439453px;
    width: 24px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector044 {
    top: 38.74886703491211px;
    left: 119.9338150024414px;
    width: 24px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector045 {
    top: 41.430824279785156px;
    left: 120.15133666992188px;
    width: 24px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector046 {
    top: 44.1127815246582px;
    left: 120.36885833740234px;
    width: 25px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector047 {
    top: 46.79473876953125px;
    left: 120.58976745605469px;
    width: 25px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector048 {
    top: 49.46894836425781px;
    left: 120.80728912353516px;
    width: 25px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector049 {
    top: 52.14315414428711px;
    left: 121.02481079101562px;
    width: 25px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector050 {
    top: 54.825111389160156px;
    left: 121.24571990966797px;
    width: 26px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector051 {
    top: 57.49931716918945px;
    left: 121.45307159423828px;
    width: 26px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector052 {
    top: 60.18902587890625px;
    left: 121.6807632446289px;
    width: 26px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector053 {
    top: 62.85548400878906px;
    left: 121.91815185546875px;
    width: 26px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector054 {
    top: 65.53743743896484px;
    left: 122.11920166015625px;
    width: 27px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector055 {
    top: 12.037805557250977px;
    left: 90.02726745605469px;
    width: 25px;
    height: 10px;
    position: absolute;
  }
  .verifying-prescription-vector056 {
    top: 14.89804458618164px;
    left: 90.01370239257812px;
    width: 26px;
    height: 10px;
    position: absolute;
  }
  .verifying-prescription-vector057 {
    top: 17.742780685424805px;
    left: 90.03065490722656px;
    width: 26px;
    height: 10px;
    position: absolute;
  }
  .verifying-prescription-vector058 {
    top: 20.610769271850586px;
    left: 90.04712677001953px;
    width: 26px;
    height: 10px;
    position: absolute;
  }
  .verifying-prescription-vector059 {
    top: 23.45550537109375px;
    left: 90.0640869140625px;
    width: 26px;
    height: 10px;
    position: absolute;
  }
  .verifying-prescription-vector060 {
    top: 26.300241470336914px;
    left: 90.08103942871094px;
    width: 26px;
    height: 9px;
    position: absolute;
  }
  .verifying-prescription-vector061 {
    top: 29.129474639892578px;
    left: 90.0975112915039px;
    width: 27px;
    height: 9px;
    position: absolute;
  }
  .verifying-prescription-vector062 {
    top: 31.989713668823242px;
    left: 90.11447143554688px;
    width: 27px;
    height: 9px;
    position: absolute;
  }
  .verifying-prescription-vector063 {
    top: 34.82669448852539px;
    left: 90.13094329833984px;
    width: 27px;
    height: 9px;
    position: absolute;
  }
  .verifying-prescription-vector064 {
    top: 37.67918395996094px;
    left: 90.16436767578125px;
    width: 27px;
    height: 9px;
    position: absolute;
  }
  .verifying-prescription-vector065 {
    top: 40.50066375732422px;
    left: 90.16436767578125px;
    width: 27px;
    height: 9px;
    position: absolute;
  }
  .verifying-prescription-vector066 {
    top: 43.345401763916016px;
    left: 90.18810272216797px;
    width: 28px;
    height: 9px;
    position: absolute;
  }
  .verifying-prescription-vector067 {
    top: 46.2133903503418px;
    left: 90.19779205322266px;
    width: 28px;
    height: 8px;
    position: absolute;
  }
  .verifying-prescription-vector068 {
    top: 49.050376892089844px;
    left: 90.2215347290039px;
    width: 28px;
    height: 8px;
    position: absolute;
  }
  .verifying-prescription-vector069 {
    top: 51.902862548828125px;
    left: 90.2312240600586px;
    width: 28px;
    height: 8px;
    position: absolute;
  }
  .verifying-prescription-vector070 {
    top: 54.71659469604492px;
    left: 90.24817657470703px;
    width: 28px;
    height: 8px;
    position: absolute;
  }
  .verifying-prescription-vector071 {
    top: 57.57683181762695px;
    left: 90.26513671875px;
    width: 28px;
    height: 8px;
    position: absolute;
  }
  .verifying-prescription-vector072 {
    top: 0.16277779638767242px;
    left: 13.50086784362793px;
    width: 74px;
    height: 73px;
    position: absolute;
  }
  .verifying-prescription-vector073 {
    top: 0px;
    left: 13.309991836547852px;
    width: 74px;
    height: 74px;
    position: absolute;
  }
  .verifying-prescription-vector074 {
    top: 19.339553833007812px;
    left: 28.739971160888672px;
    width: 25px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-vector075 {
    top: 16.262277603149414px;
    left: 27.972105026245117px;
    width: 25px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector076 {
    top: 18.866722106933594px;
    left: 27.700809478759766px;
    width: 25px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector077 {
    top: 21.478918075561523px;
    left: 27.42999839782715px;
    width: 25px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector078 {
    top: 24.083362579345703px;
    left: 27.158700942993164px;
    width: 26px;
    height: 6px;
    position: absolute;
  }
  .verifying-prescription-vector079 {
    top: 26.687807083129883px;
    left: 26.887889862060547px;
    width: 26px;
    height: 7px;
    position: absolute;
  }
  .verifying-prescription-vector080 {
    top: 29.292253494262695px;
    left: 26.616592407226562px;
    width: 26px;
    height: 7px;
    position: absolute;
  }
  .verifying-prescription-vector081 {
    top: 31.904449462890625px;
    left: 26.34529685974121px;
    width: 26px;
    height: 7px;
    position: absolute;
  }
  .verifying-prescription-vector082 {
    top: 34.50889205932617px;
    left: 26.074485778808594px;
    width: 26px;
    height: 7px;
    position: absolute;
  }
  .verifying-prescription-vector083 {
    top: 37.105587005615234px;
    left: 25.79979705810547px;
    width: 27px;
    height: 7px;
    position: absolute;
  }
  .verifying-prescription-vector084 {
    top: 39.71002960205078px;
    left: 25.52898597717285px;
    width: 27px;
    height: 7px;
    position: absolute;
  }
  .verifying-prescription-vector085 {
    top: 42.322227478027344px;
    left: 25.257688522338867px;
    width: 27px;
    height: 7px;
    position: absolute;
  }
  .verifying-prescription-vector086 {
    top: 44.92667007446289px;
    left: 24.98687744140625px;
    width: 27px;
    height: 8px;
    position: absolute;
  }
  .verifying-prescription-vector087 {
    top: 47.5311164855957px;
    left: 24.7155818939209px;
    width: 27px;
    height: 8px;
    position: absolute;
  }
  .verifying-prescription-vector088 {
    top: 50.1278076171875px;
    left: 24.441377639770508px;
    width: 28px;
    height: 8px;
    position: absolute;
  }
  .verifying-prescription-vector089 {
    top: 52.74000549316406px;
    left: 24.173473358154297px;
    width: 28px;
    height: 8px;
    position: absolute;
  }
  .verifying-prescription-vector090 {
    top: 55.336700439453125px;
    left: 23.902175903320312px;
    width: 28px;
    height: 8px;
    position: absolute;
  }
  .verifying-prescription-vector091 {
    top: 7.960609436035156px;
    left: 56.4092903137207px;
    width: 27px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector092 {
    top: 10.696826934814453px;
    left: 56.33565139770508px;
    width: 27px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector093 {
    top: 13.41754150390625px;
    left: 56.26540756225586px;
    width: 27px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector094 {
    top: 16.130504608154297px;
    left: 56.188377380371094px;
    width: 27px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector095 {
    top: 18.812463760375977px;
    left: 56.11812973022461px;
    width: 27px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector096 {
    top: 21.517675399780273px;
    left: 56.04449462890625px;
    width: 27px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector097 {
    top: 24.19188117980957px;
    left: 55.970855712890625px;
    width: 27px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector098 {
    top: 26.87384033203125px;
    left: 55.897220611572266px;
    width: 27px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector099 {
    top: 29.555797576904297px;
    left: 55.82358169555664px;
    width: 27px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector100 {
    top: 32.214500427246094px;
    left: 55.749942779541016px;
    width: 27px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector101 {
    top: 34.88095474243164px;
    left: 55.676307678222656px;
    width: 27px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector102 {
    top: 37.53190994262695px;
    left: 55.60266876220703px;
    width: 27px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector103 {
    top: 40.20611572265625px;
    left: 55.53920364379883px;
    width: 28px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector104 {
    top: 42.84931564331055px;
    left: 55.45539474487305px;
    width: 28px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector105 {
    top: 45.508018493652344px;
    left: 55.38514709472656px;
    width: 28px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector106 {
    top: 48.158973693847656px;
    left: 55.31150817871094px;
    width: 28px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector107 {
    top: 50.7944221496582px;
    left: 55.23787307739258px;
    width: 28px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector108 {
    top: 75.0948257446289px;
    left: 110.49948120117188px;
    width: 3px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-vector109 {
    top: 69.68440246582031px;
    left: 84.99713897705078px;
    width: 24px;
    height: 5px;
    position: absolute;
  }
  .verifying-prescription-vector110 {
    top: 66.28157043457031px;
    left: 81.4460678100586px;
    width: 3px;
    height: 3px;
    position: absolute;
  }
  .verifying-prescription-vector111 {
    top: 62.374900817871094px;
    left: 61.54987335205078px;
    width: 20px;
    height: 4px;
    position: absolute;
  }
  .verifying-prescription-vector112 {
    top: 64.04918670654297px;
    left: 42.07176208496094px;
    width: 36px;
    height: 4px;
    position: absolute;
  }
  .verifying-prescription-vector113 {
    top: 66.28932189941406px;
    left: 79.3110580444336px;
    width: 2px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-vector114 {
    top: 74.97855377197266px;
    left: 29.179859161376953px;
    width: 51px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-vector115 {
    top: 72.4593734741211px;
    left: 81.74836730957031px;
    width: 3px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-vector116 {
    top: 77.35820770263672px;
    left: 42.019927978515625px;
    width: 3px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-vector117 {
    top: 78.00157165527344px;
    left: 16.95020866394043px;
    width: 23px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-vector118 {
    top: 79.2495346069336px;
    left: 74.60601043701172px;
    width: 3px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-vector119 {
    top: 80.29595947265625px;
    left: 39.58552551269531px;
    width: 33px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-vector120 {
    top: 76.56757354736328px;
    left: 56.68833923339844px;
    width: 7px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-vector121 {
    top: 76.26527404785156px;
    left: 65.47591400146484px;
    width: 17px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-vector122 {
    top: 80.17969512939453px;
    left: 31.607961654663086px;
    width: 2px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-vector123 {
    top: 80.82305145263672px;
    left: 7.925728797912598px;
    width: 22px;
    height: 1px;
    position: absolute;
  }
  .verifying-prescription-glasses {
    top: 54.345314025878906px;
    left: 86.80461120605469px;
    width: 85.58702850341797px;
    height: 57.09565734863281px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .verifying-prescription-vector124 {
    top: 0.17052911221981049px;
    left: 2.1248316764831543px;
    width: 23px;
    height: 17px;
    position: absolute;
  }
  .verifying-prescription-vector125 {
    top: 0px;
    left: 1.7900712490081787px;
    width: 24px;
    height: 17px;
    position: absolute;
  }
  .verifying-prescription-vector126 {
    top: 22.339313507080078px;
    left: 81.38551330566406px;
    width: 4px;
    height: 11px;
    position: absolute;
  }
  .verifying-prescription-vector127 {
    top: 22.153282165527344px;
    left: 81.2145004272461px;
    width: 4px;
    height: 12px;
    position: absolute;
  }
  .verifying-prescription-vector128 {
    top: 16.254526138305664px;
    left: 0.16956020891666412px;
    width: 85px;
    height: 41px;
    position: absolute;
  }
  .verifying-prescription-vector129 {
    top: 16.068492889404297px;
    left: 0px;
    width: 86px;
    height: 41px;
    position: absolute;
  }
  .verifying-prescription-vector130 {
    top: 26.37775421142578px;
    left: 27.034679412841797px;
    width: 10px;
    height: 7px;
    position: absolute;
  }
  .verifying-prescription-vector131 {
    top: 31.59439468383789px;
    left: 39.94644546508789px;
    width: 24px;
    height: 10px;
    position: absolute;
  }
  .verifying-prescription-vector132 {
    top: 17.362964630126953px;
    left: 1.408803105354309px;
    width: 25px;
    height: 9px;
    position: absolute;
  }
  .verifying-prescription-vector133 {
    top: 29.268997192382812px;
    left: 1.3463079929351807px;
    width: 3px;
    height: 7px;
    position: absolute;
  }
  .verifying-prescription-frame407 {
    gap: 4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .verifying-prescription-text {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .verifying-prescription-text2 {
    color: var(--dl-color-slate-600);
    width: 231px;
    height: auto;
    text-align: center;
    line-height: 17.5px;
  }
  .verifying-prescription-frame410 {
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .verifying-prescription-frame408 {
    width: 8px;
    height: 8px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 27px;
    background-color: var(--dl-color-brand-lavendar);
  }
  .verifying-prescription-frame409 {
    width: 8px;
    height: 8px;
    display: flex;
    opacity: 0.20;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 27px;
    background-color: var(--dl-color-brand-lavendar);
  }
  .verifying-prescription-frame4101 {
    width: 8px;
    height: 8px;
    display: flex;
    opacity: 0.20;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 27px;
    background-color: var(--dl-color-brand-lavendar);
  }
  .verifying-prescription-frame411 {
    width: 8px;
    height: 8px;
    display: flex;
    opacity: 0.20;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 27px;
    background-color: var(--dl-color-brand-lavendar);
  }
  .verifying-prescription-text4 {
    color: var(--dl-color-brand-lavendardark);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  
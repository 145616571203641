.order-status-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
  }
  .order-status-frame215 {
    gap: 20px;
    width: 100%;
    height: auto;
    display: flex;
    padding: 48px 100px;
    overflow: hidden;
    align-items: flex-start;
    flex-shrink: 0;
    flex-direction: column;
    background-color: var(--dl-color-shades-offwhite);
  }
  .order-status-frame471 {
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-frame470 {
    gap: 16px;
    display: flex;
    padding: 12px 24px;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    background-color: var(--dl-color-slate-100);
  }
  .order-status-text {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame381 {
    gap: 16px;
    display: flex;
    padding: 16px 24px;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-color: rgba(203, 213, 225, 1);
    border-style: solid;
    border-width: 0 0 1px;
    justify-content: space-between;
    background-color: var(--dl-color-shades-white);
  }
  .order-status-text002 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 20px;
  }
  .order-status-frame460 {
    gap: 24px;
    display: flex;
    align-items: center;
  }
  .order-status-frame278 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text004 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-text006 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-frame2781 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text008 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-text010 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-text012 {
    color: var(--dl-color-brand-lavendardark);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame469 {
    gap: 20px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .order-status-frame332 {
    gap: 16px;
    width: 790px;
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    flex-direction: column;
  }
  .order-status-frame384 {
    gap: 16px;
    display: flex;
    padding: 0 0 32px;
    align-self: stretch;
    align-items: flex-start;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 0 0 1px;
    flex-direction: column;
  }
  .order-status-text014 {
    color: var(--dl-color-slate-600);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-frame3841 {
    gap: 8px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-frame281 {
    gap: 16px;
    display: flex;
    padding: 16px 24px;
    overflow: hidden;
    align-self: stretch;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.029999999329447746) ;
    align-items: flex-start;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
  }
  .order-status-frame269 {
    gap: 4px;
    display: flex;
    padding: 4px;
    align-items: center;
    border-radius: 4px;
    background-color: var(--dl-color-lime-50);
  }
  .order-status-interface-essential-done-check {
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .order-status-group {
    top: -5.000000058430487e-8px;
    left: -5.000000058430487e-8px;
    width: 16px;
    height: 16px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .order-status-group01 {
    top: 0px;
    left: 0px;
    width: 16px;
    height: 16px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .order-status-path {
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    position: absolute;
  }
  .order-status-path01 {
    top: 6.666666507720947px;
    left: 5.333333492279053px;
    width: 5px;
    height: 3px;
    position: absolute;
  }
  .order-status-text016 {
    color: var(--dl-color-lime-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame379 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
  }
  .order-status-frame182 {
    gap: 8px;
    display: flex;
    align-items: center;
  }
  .order-status-frame177 {
    width: 58px;
    height: 58px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: var(--dl-color-slate-100);
  }
  .order-status-frame218 {
    gap: 8px;
    top: 0px;
    left: 0px;
    width: 58px;
    height: 58px;
    display: flex;
    padding: 8px;
    overflow: hidden;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    background-size: cover;
    background-image: 6e0ea42e-bb6c-4614-8f7c-35ff4320cda4;
  }
  .order-status-frame178 {
    gap: 4px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text018 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-text020 {
    color: var(--dl-color-slate-600);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame296 {
    gap: 16px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-frame2782 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text022 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame284 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-frame283 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
    border-color: rgba(15, 23, 42, 1);
    border-style: solid;
    border-width: 0 0 1px;
  }
  .order-status-text024 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame279 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text026 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame28301 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
    border-color: rgba(15, 23, 42, 1);
    border-style: solid;
    border-width: 0 0 1px;
  }
  .order-status-text028 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame382 {
    gap: 8px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-frame380 {
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
  }
  .order-status-frame180 {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  .order-status-text030 {
    color: rgba(15, 23, 42, 1);
    height: auto;
    font-size: 14px;
    font-style: Medium;
    text-align: left;
    font-family: Helvetica Neue;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .order-status-text031 {
    color: var(--dl-color-slate-900);
    font-weight: 500;
    text-decoration: NONE;
  }
  .order-status-text033 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame285 {
    gap: 16px;
    display: flex;
    padding: 16px 24px;
    overflow: hidden;
    align-self: stretch;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.029999999329447746) ;
    align-items: flex-start;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
  }
  .order-status-frame2691 {
    gap: 4px;
    display: flex;
    padding: 4px;
    align-items: center;
    border-radius: 4px;
    background-color: var(--dl-color-lime-50);
  }
  .order-status-interface-essential-done-check1 {
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .order-status-group02 {
    top: -5.000000058430487e-8px;
    left: -5.000000058430487e-8px;
    width: 16px;
    height: 16px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .order-status-group03 {
    top: 0px;
    left: 0px;
    width: 16px;
    height: 16px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .order-status-path02 {
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    position: absolute;
  }
  .order-status-path03 {
    top: 6.666666507720947px;
    left: 5.333333492279053px;
    width: 5px;
    height: 3px;
    position: absolute;
  }
  .order-status-text035 {
    color: var(--dl-color-lime-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame3791 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
  }
  .order-status-frame1821 {
    gap: 8px;
    display: flex;
    align-items: center;
  }
  .order-status-frame1771 {
    width: 58px;
    height: 58px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: var(--dl-color-slate-100);
  }
  .order-status-frame2181 {
    gap: 8px;
    top: 0px;
    left: 0px;
    width: 58px;
    height: 58px;
    display: flex;
    padding: 8px;
    overflow: hidden;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    background-size: cover;
    background-image: 21a43b77-7295-46d8-a889-b37ff33e6f0e;
  }
  .order-status-frame1781 {
    gap: 4px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text037 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-text039 {
    color: var(--dl-color-slate-600);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame2961 {
    gap: 16px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-frame2783 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text041 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame2841 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-frame28302 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
    border-color: rgba(15, 23, 42, 1);
    border-style: solid;
    border-width: 0 0 1px;
  }
  .order-status-text043 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame2791 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text045 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame28303 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
    border-color: rgba(15, 23, 42, 1);
    border-style: solid;
    border-width: 0 0 1px;
  }
  .order-status-text047 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame3821 {
    gap: 8px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-frame3801 {
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
  }
  .order-status-frame1801 {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  .order-status-text049 {
    color: rgba(15, 23, 42, 1);
    height: auto;
    font-size: 14px;
    font-style: Medium;
    text-align: left;
    font-family: Helvetica Neue;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .order-status-text050 {
    color: var(--dl-color-slate-900);
    font-weight: 500;
    text-decoration: NONE;
  }
  .order-status-text052 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame383 {
    gap: 16px;
    display: flex;
    padding: 0 0 32px;
    align-self: stretch;
    align-items: flex-start;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 0 0 1px;
    flex-direction: column;
  }
  .order-status-text054 {
    color: var(--dl-color-slate-600);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-frame472 {
    gap: 8px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-frame2851 {
    gap: 16px;
    display: flex;
    padding: 16px 24px;
    overflow: hidden;
    align-self: stretch;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.029999999329447746) ;
    align-items: flex-start;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
  }
  .order-status-frame3792 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
  }
  .order-status-frame1822 {
    gap: 8px;
    display: flex;
    align-items: center;
  }
  .order-status-frame1772 {
    width: 58px;
    height: 58px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: var(--dl-color-slate-100);
  }
  .order-status-frame2182 {
    gap: 8px;
    top: 0px;
    left: 0px;
    width: 58px;
    height: 58px;
    display: flex;
    padding: 8px;
    overflow: hidden;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    background-size: cover;
    background-image: 3826e17b-0572-4f78-acb5-df1432ff0a37;
  }
  .order-status-frame1782 {
    gap: 4px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text056 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-text058 {
    color: var(--dl-color-slate-600);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame2962 {
    gap: 16px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-frame2784 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text060 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame2842 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-frame28304 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
    border-color: rgba(15, 23, 42, 1);
    border-style: solid;
    border-width: 0 0 1px;
  }
  .order-status-text062 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame280 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text064 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame28305 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-text066 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame3822 {
    gap: 8px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-frame3802 {
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
  }
  .order-status-frame1802 {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  .order-status-text068 {
    color: rgba(15, 23, 42, 1);
    height: auto;
    font-size: 14px;
    font-style: Medium;
    text-align: left;
    font-family: Helvetica Neue;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .order-status-text069 {
    color: var(--dl-color-slate-900);
    font-weight: 500;
    text-decoration: NONE;
  }
  .order-status-text071 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame385 {
    gap: 16px;
    display: flex;
    padding: 16px 24px;
    overflow: hidden;
    align-self: stretch;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.029999999329447746) ;
    align-items: flex-start;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
  }
  .order-status-frame3793 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
  }
  .order-status-frame1823 {
    gap: 8px;
    display: flex;
    align-items: center;
  }
  .order-status-frame1773 {
    width: 58px;
    height: 58px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: var(--dl-color-slate-100);
  }
  .order-status-frame2183 {
    gap: 8px;
    top: 0px;
    left: 0px;
    width: 58px;
    height: 58px;
    display: flex;
    padding: 8px;
    overflow: hidden;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    background-size: cover;
    background-image: cd17b6b4-3a3f-4249-bed0-1331d72c6258;
  }
  .order-status-frame1783 {
    gap: 4px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text073 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-text075 {
    color: var(--dl-color-slate-600);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame2963 {
    gap: 16px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-frame2785 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text077 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame2843 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-frame28306 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
    border-color: rgba(15, 23, 42, 1);
    border-style: solid;
    border-width: 0 0 1px;
  }
  .order-status-text079 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame2792 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text081 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame28307 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-text083 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame3823 {
    gap: 8px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-frame3803 {
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
  }
  .order-status-frame1803 {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  .order-status-text085 {
    color: rgba(15, 23, 42, 1);
    height: auto;
    font-size: 14px;
    font-style: Medium;
    text-align: left;
    font-family: Helvetica Neue;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .order-status-text086 {
    color: var(--dl-color-slate-900);
    font-weight: 500;
    text-decoration: NONE;
  }
  .order-status-text088 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame386 {
    gap: 16px;
    display: flex;
    padding: 16px 24px;
    overflow: hidden;
    align-self: stretch;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.029999999329447746) ;
    align-items: flex-start;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
  }
  .order-status-frame3794 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
  }
  .order-status-frame1824 {
    gap: 8px;
    display: flex;
    align-items: center;
  }
  .order-status-frame1774 {
    width: 58px;
    height: 58px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: var(--dl-color-slate-100);
  }
  .order-status-frame2184 {
    gap: 8px;
    top: 0px;
    left: 0px;
    width: 58px;
    height: 58px;
    display: flex;
    padding: 8px;
    overflow: hidden;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    background-size: cover;
    background-image: fa908f09-595f-42cc-917f-fe23820783ed;
  }
  .order-status-frame1784 {
    gap: 4px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text090 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-text092 {
    color: var(--dl-color-slate-600);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame2964 {
    gap: 16px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-frame2786 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text094 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame2844 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-frame28308 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
    border-color: rgba(15, 23, 42, 1);
    border-style: solid;
    border-width: 0 0 1px;
  }
  .order-status-text096 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame2793 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text098 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame28309 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-text100 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame3824 {
    gap: 8px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-frame3804 {
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
  }
  .order-status-frame1804 {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  .order-status-text102 {
    color: rgba(15, 23, 42, 1);
    height: auto;
    font-size: 14px;
    font-style: Medium;
    text-align: left;
    font-family: Helvetica Neue;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .order-status-text103 {
    color: var(--dl-color-slate-900);
    font-weight: 500;
    text-decoration: NONE;
  }
  .order-status-text105 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame387 {
    gap: 16px;
    display: flex;
    padding: 16px 24px;
    overflow: hidden;
    align-self: stretch;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.029999999329447746) ;
    align-items: flex-start;
    border-color: rgba(226, 232, 240, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 1);
  }
  .order-status-frame3795 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
  }
  .order-status-frame1825 {
    gap: 8px;
    display: flex;
    align-items: center;
  }
  .order-status-frame1775 {
    width: 58px;
    height: 58px;
    display: flex;
    overflow: hidden;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: var(--dl-color-slate-100);
  }
  .order-status-frame2185 {
    gap: 8px;
    top: 0px;
    left: 0px;
    width: 58px;
    height: 58px;
    display: flex;
    padding: 8px;
    overflow: hidden;
    position: absolute;
    align-items: center;
    flex-shrink: 0;
    background-size: cover;
    background-image: 49b6ed7e-4743-466d-9e04-9437b5edafbc;
  }
  .order-status-frame1785 {
    gap: 4px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text107 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-text109 {
    color: var(--dl-color-slate-600);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame2965 {
    gap: 16px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-frame2787 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text111 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame2845 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-frame28310 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
    border-color: rgba(15, 23, 42, 1);
    border-style: solid;
    border-width: 0 0 1px;
  }
  .order-status-text113 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame2794 {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text115 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame28311 {
    gap: 8px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-text117 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 12.5px;
  }
  .order-status-frame3825 {
    gap: 8px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-frame3805 {
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
  }
  .order-status-frame1805 {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  .order-status-text119 {
    color: rgba(15, 23, 42, 1);
    height: auto;
    font-size: 14px;
    font-style: Medium;
    text-align: left;
    font-family: Helvetica Neue;
    font-weight: 500;
    line-height: normal;
    font-stretch: normal;
    text-decoration: none;
  }
  .order-status-text120 {
    color: var(--dl-color-slate-900);
    font-weight: 500;
    text-decoration: NONE;
  }
  .order-status-text122 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame288 {
    width: 430px;
    display: flex;
    padding: 32px 0 0;
    overflow: hidden;
    flex-grow: 1;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    flex-direction: column;
    justify-content: flex-end;
  }
  .order-status-prescriptionprescriptionapplied {
    gap: 16px;
    display: flex;
    padding: 24px 12px;
    align-self: stretch;
    align-items: flex-start;
    border-color: rgba(203, 213, 225, 1);
    border-style: solid;
    border-width: 0 0 1px;
    flex-direction: column;
    background-color: var(--dl-color-shades-white);
  }
  .order-status-frame398 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
  }
  .order-status-frame3851 {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  .order-status-interface-essential-done-check2 {
    width: 24px;
    height: 24px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .order-status-group04 {
    top: -5.000000058430487e-8px;
    left: -5.000000058430487e-8px;
    width: 24px;
    height: 24px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .order-status-group05 {
    top: 0px;
    left: 0px;
    width: 24px;
    height: 24px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .order-status-path04 {
    top: 3px;
    left: 3px;
    width: 18px;
    height: 18px;
    position: absolute;
  }
  .order-status-path05 {
    top: 10px;
    left: 8px;
    width: 8px;
    height: 5px;
    position: absolute;
  }
  .order-status-text124 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 20px;
  }
  .order-status-text126 {
    color: var(--dl-color-brand-lavendardark);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-frame2692 {
    gap: 4px;
    display: flex;
    padding: 12px 4px;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    border-radius: 4px;
    background-color: var(--dl-color-slate-50);
  }
  .order-status-frame402 {
    gap: 4px;
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
  .order-status-interface-essential-done-check3 {
    width: 16px;
    height: 16px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .order-status-group06 {
    top: -5.000000058430487e-8px;
    left: -5.000000058430487e-8px;
    width: 16px;
    height: 16px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .order-status-group07 {
    top: 0px;
    left: 0px;
    width: 16px;
    height: 16px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .order-status-path06 {
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    position: absolute;
  }
  .order-status-path07 {
    top: 6.666666507720947px;
    left: 5.333333492279053px;
    width: 5px;
    height: 3px;
    position: absolute;
  }
  .order-status-text128 {
    color: var(--dl-color-slate-800);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-frame395 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-frame12 {
    gap: 8px;
    display: flex;
    padding: 12px 16px;
    overflow: hidden;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(3, 27, 137, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    justify-content: center;
  }
  .order-status-text130 {
    color: var(--dl-color-brand-blueprimary);
    height: auto;
    text-align: left;
    line-height: 17.5px;
  }
  .order-status-applycoupon-couponapplied {
    gap: 16px;
    display: flex;
    padding: 24px 12px;
    align-self: stretch;
    align-items: flex-start;
    border-color: rgba(203, 213, 225, 1);
    border-style: solid;
    border-width: 0 0 1px;
    flex-direction: column;
    background-color: var(--dl-color-shades-white);
  }
  .order-status-frame401 {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  .order-status-content-edit-document {
    width: 24px;
    height: 24px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .order-status-group08 {
    top: -5.000000058430487e-8px;
    left: -5.000000058430487e-8px;
    width: 24px;
    height: 24px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .order-status-group09 {
    top: 3px;
    left: 5px;
    width: 14px;
    height: 18px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .order-status-path08 {
    top: 0px;
    left: 2.6645352591003757e-15px;
    width: 14px;
    height: 18px;
    position: absolute;
  }
  .order-status-path09 {
    top: 8.730000495910645px;
    left: 3px;
    width: 8px;
    height: 1px;
    position: absolute;
  }
  .order-status-path10 {
    top: 11.730000495910645px;
    left: 3px;
    width: 8px;
    height: 1px;
    position: absolute;
  }
  .order-status-path11 {
    top: 14.5px;
    left: 3px;
    width: 5px;
    height: 1px;
    position: absolute;
  }
  .order-status-path12 {
    top: 3px;
    left: 3px;
    width: 3px;
    height: 3px;
    position: absolute;
  }
  .order-status-text132 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 20px;
  }
  .order-status-frame399 {
    gap: 12px;
    display: flex;
    padding: 0 0 16px;
    align-self: stretch;
    align-items: flex-start;
    border-color: rgba(148, 163, 184, 1);
    border-style: solid;
    border-width: 0 0 0.5px;
    flex-direction: column;
  }
  .order-status-frame3861 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .order-status-text134 {
    color: var(--dl-color-slate-800);
    height: auto;
    flex-grow: 1;
    text-align: left;
    line-height: 15px;
  }
  .order-status-text136 {
    color: var(--dl-color-slate-800);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-frame3871 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .order-status-frame403 {
    gap: 8px;
    width: 345px;
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    flex-shrink: 0;
    flex-direction: column;
  }
  .order-status-text138 {
    color: var(--dl-color-slate-800);
    height: auto;
    align-self: stretch;
    text-align: left;
    line-height: 15px;
  }
  .order-status-frame404 {
    gap: 4px;
    display: flex;
    padding: 0 24px;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-text140 {
    color: var(--dl-color-slate-500);
    height: auto;
    align-self: stretch;
    text-align: left;
    line-height: 15px;
  }
  .order-status-text142 {
    color: var(--dl-color-slate-500);
    height: auto;
    align-self: stretch;
    text-align: left;
    line-height: 15px;
  }
  .order-status-frame405 {
    gap: 8px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .order-status-text144 {
    color: var(--dl-color-slate-800);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-frame406 {
    gap: 4px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  .order-status-text146 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-text148 {
    color: var(--dl-color-slate-500);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-frame388 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .order-status-text150 {
    color: var(--dl-color-slate-800);
    height: auto;
    flex-grow: 1;
    text-align: left;
    line-height: 15px;
  }
  .order-status-text152 {
    color: var(--dl-color-slate-800);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-frame389 {
    gap: 4px;
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .order-status-text154 {
    color: var(--dl-color-slate-900);
    height: auto;
    flex-grow: 1;
    text-align: left;
    line-height: 20px;
  }
  .order-status-text156 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 20px;
  }
  .order-status-applycoupon-couponapplied1 {
    gap: 16px;
    display: flex;
    padding: 24px 12px;
    align-self: stretch;
    align-items: flex-start;
    border-color: rgba(203, 213, 225, 1);
    border-style: solid;
    border-width: 0 0 1px;
    flex-direction: column;
    background-color: var(--dl-color-shades-white);
  }
  .order-status-frame400 {
    gap: 16px;
    display: flex;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
  }
  .order-status-frame4011 {
    gap: 4px;
    display: flex;
    align-items: center;
  }
  .order-status-real-estatehomehousebig {
    width: 24px;
    height: 24px;
    display: flex;
    position: relative;
    align-items: flex-start;
    flex-shrink: 0;
  }
  .order-status-group10 {
    top: -0.004999999888241291px;
    left: -0.004999999888241291px;
    width: 24.010000228881836px;
    height: 24.010000228881836px;
    display: flex;
    position: absolute;
    align-items: flex-start;
    flex-shrink: 1;
  }
  .order-status-path13 {
    top: 4.501914024353027px;
    left: 3.0012500286102295px;
    width: 18px;
    height: 7px;
    position: absolute;
  }
  .order-status-path14 {
    top: 4.00166654586792px;
    left: 16.00666618347168px;
    width: 4px;
    height: 17px;
    position: absolute;
  }
  .order-status-path15 {
    top: 14.506041526794434px;
    left: 9.549427032470703px;
    width: 5px;
    height: 7px;
    position: absolute;
  }
  .order-status-path16 {
    top: 10.598143577575684px;
    left: 3.7870771884918213px;
    width: 1px;
    height: 10px;
    position: absolute;
  }
  .order-status-path17 {
    top: 20.508541107177734px;
    left: 3.0012500286102295px;
    width: 18px;
    height: 1px;
    position: absolute;
  }
  .order-status-text158 {
    color: var(--dl-color-slate-900);
    height: auto;
    text-align: left;
    line-height: 20px;
  }
  .order-status-frame169 {
    gap: 8px;
    display: flex;
    padding: 8px 4px;
    align-self: stretch;
    align-items: flex-start;
    border-radius: 4px;
    flex-direction: column;
    background-color: var(--dl-color-slate-50);
  }
  .order-status-frame170 {
    gap: 4px;
    display: flex;
    align-items: flex-start;
  }
  .order-status-text160 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-text162 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-text163 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    text-align: left;
    line-height: 15px;
  }
  .order-status-text165 {
    color: rgba(0, 0, 0, 1);
    height: auto;
    align-self: stretch;
    text-align: left;
    line-height: 15px;
  }
  .order-status-frame2852 {
    gap: 8px;
    display: flex;
    padding: 16px 0;
    align-self: stretch;
    align-items: flex-start;
    flex-direction: column;
  }
  .order-status-frame121 {
    gap: 8px;
    display: flex;
    padding: 16px;
    overflow: hidden;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: 4px;
    justify-content: center;
    background-color: var(--dl-color-brand-blueprimary);
  }
  .order-status-text167 {
    color: var(--dl-color-shades-white);
    height: auto;
    text-align: left;
    line-height: 20px;
  }
  .order-status-frame13 {
    gap: 8px;
    display: flex;
    padding: 16px;
    overflow: hidden;
    align-self: stretch;
    align-items: flex-start;
    flex-shrink: 0;
    border-color: rgba(3, 27, 137, 1);
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    justify-content: center;
    background-color: var(--dl-color-shades-white);
  }
  .order-status-text169 {
    color: var(--dl-color-brand-blueprimary);
    height: auto;
    text-align: left;
    line-height: 20px;
  }
  

.txn-fail-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.txn-fail-frame215 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 48px 100px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  background-color: var(--dl-color-shades-offwhite);
}
.txn-fail-frame332 {
  gap: 16px;
  width: 744px;
  display: flex;
  padding: 48px 0;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.txn-fail-frame383 {
  gap: 16px;
  display: flex;
  padding: 0 0 32px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.txn-fail-frame413 {
  gap: 8px;
  display: flex;
  padding: 56px;
  overflow: hidden;
  align-items: center;
  border-radius: 165px;
  justify-content: center;
  background-color: var(--dl-color-red-600);
}
.txn-fail-interface-essential-delete-disabled {
  width: 48px;
  height: 48px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.txn-fail-group {
  top: -5.000000058430487e-8px;
  left: -5.000000058430487e-8px;
  width: 48px;
  height: 48px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.txn-fail-group1 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.txn-fail-path {
  top: 16px;
  left: 16px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.txn-fail-path1 {
  top: 16px;
  left: 16px;
  width: 16px;
  height: 16px;
  position: absolute;
}
.txn-fail-frame407 {
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.txn-fail-text {
  color: var(--dl-color-slate-900);
  height: auto;
  text-align: left;
  line-height: 17.5px;
}
.txn-fail-text2 {
  color: var(--dl-color-slate-600);
  width: 231px;
  height: auto;
  text-align: center;
  line-height: 17.5px;
}
.txn-fail-frame12 {
  gap: 8px;
  display: flex;
  padding: 16px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  justify-content: center;
  background-color: var(--dl-color-brand-blueprimary);
}
.txn-fail-text4 {
  color: var(--dl-color-shades-white);
  height: auto;
  text-align: left;
  line-height: 20px;
}

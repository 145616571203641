body {
    @apply leading-[normal] m-0;
    @apply pt-[4.5rem];
    @apply lg:pt-0;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.shadow-custom{
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.03), 0px 0px 10px 0px rgba(0, 0, 0, 0.03);
}

.shadow-login{
    box-shadow: 0px 4px 17px 0px rgba(16, 16, 16, 0.10);
}

.shadow-cartItem{
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
}

.shadow-orders-section{
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.05), 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
}

.table-scroll{
  max-height: 489px !important;
  overflow: scroll;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    cursor: pointer;
  }
  
  .dropdown-content a:hover {
    background-color: #ddd;
  }
  
  .dropdown:hover .dropdown-content {
    display: block;
  }
  /* styles.css */

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .loader-container p {
    margin-top: 10px;
    color: #ffffff;
  }
  .maps {
    /* height: 50vh;
    width: 45vw; */
    position: relative;
    height: 50vh;
    width: 125%;
  }
  
  .map-container {
    height: 80%;
    width: 80%;
  }
  .custom-file-upload {
    display: inline-block;
    padding: 12px 20px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    text-align: center;
    font-size: 16px; /* Adjust font size as needed */
    font-weight: bold; /* Adjust font weight as needed */
    margin: 0; /* Adjust margins as needed */
    text-decoration: none; /* Remove underline from the link */
  }
  
  .custom-file-upload:hover {
    background-color: #0056b3;
  }
  
  #file-upload {
    display: none;
  }
  
  /* Additional styling to match the screenshot */
  .upload-container {
    background-color: #f8f9fa; /* Adjust the background color to match the screenshot */
    border-top: 1px solid #dee2e6; /* Add border top */
    border-bottom: 1px solid #dee2e6; /* Add border bottom */
    padding: 20px; /* Add padding */
    text-align: center; /* Center the content */
  }

  .upload-container label {
    margin-right: 10px; /* Add some space between the icon and text */
  }
  
  .upload-container i {
    margin-right: 5px; /* Add space between icon and text if using font awesome */
  }

  .PhoneInputInput{
    padding: 5px !important;
  }

  .input-error {
    border: 1px solid red;
  }
  
  .error-message {
    /* Styles for the error message */
    color: red;
    margin-top: 8px;
  }
  /* wallet.css */
.wallet-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
}

.wallet-frame424 {
  background: white;
  border-radius: 12px;
  padding: 20px;
  /* max-width: 600px; */
  width: 100%;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); */
}

.wallet-frame467 {
  margin-top: 20px;
}

.wallet-frame281 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.wallet-frame474 {
  display: flex;
  align-items: center;
}

.wallet-icon {
  font-size: 32px;
  margin-right: 10px;
}

.refetch-balance-icon {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.spinning {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.wallet-frame2811 {
  margin-top: 20px;
}

.wallet-frame295 {
  margin-bottom: 20px;
}

.wallet-frame276 {
  margin-top: 10px;
}

.wallet-frame473 {
  margin-top: 20px;
}

.wallet-frame380 {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #f9f9f9;
  border-radius: 8px;
}

.no-transactions-found {
  text-align: center;
}

.transaction-success-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.transaction-success-frame215 {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  padding: 48px 100px;
  overflow: hidden;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  background-color: var(--dl-color-shades-offwhite);
}
.transaction-success-frame332 {
  gap: 16px;
  width: 744px;
  display: flex;
  padding: 48px 0;
  align-items: center;
  flex-shrink: 0;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.transaction-success-frame383 {
  gap: 16px;
  display: flex;
  padding: 0 0 32px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.transaction-success-frame413 {
  gap: 8px;
  display: flex;
  padding: 56px;
  overflow: hidden;
  align-items: center;
  border-radius: 165px;
  justify-content: center;
  background-color: var(--dl-color-brand-lavendar);
}
.transaction-success-interface-essential-done-check {
  width: 48px;
  height: 48px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.transaction-success-group {
  top: -5.000000058430487e-8px;
  left: -5.000000058430487e-8px;
  width: 48px;
  height: 48px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.transaction-success-group01 {
  top: 0px;
  left: 0px;
  width: 48px;
  height: 48px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.transaction-success-path {
  top: 13px;
  left: 8px;
  width: 32px;
  height: 22px;
  position: absolute;
}
.transaction-success-frame407 {
  gap: 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.transaction-success-text {
  color: var(--dl-color-slate-900);
  height: auto;
  text-align: left;
  line-height: 17.5px;
}
.transaction-success-text02 {
  color: var(--dl-color-slate-600);
  width: 231px;
  height: auto;
  text-align: center;
  line-height: 17.5px;
}
.transaction-success-text04 {
  color: var(--dl-color-brand-lavendardark);
  height: auto;
  text-align: left;
  line-height: 17.5px;
}
.transaction-success-frame281 {
  gap: 16px;
  display: flex;
  padding: 16px 24px;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.029999999329447746) ;
  align-items: flex-start;
  border-color: rgba(226, 232, 240, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 1);
}
.transaction-success-text06 {
  color: var(--dl-color-slate-900);
  height: auto;
  text-align: left;
  line-height: 17.5px;
}
.transaction-success-frame329 {
  gap: 8px;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.transaction-success-frame414 {
  gap: 16px;
  display: flex;
  align-items: flex-start;
}
.transaction-success-geometric-abstract-star {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.transaction-success-group02 {
  top: -5.000000058430487e-8px;
  left: -5.000000058430487e-8px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.transaction-success-group03 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.transaction-success-path1 {
  top: 3px;
  left: 3px;
  width: 18px;
  height: 17px;
  position: absolute;
}
.transaction-success-geometric-abstract-star1 {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.transaction-success-group04 {
  top: -5.000000058430487e-8px;
  left: -5.000000058430487e-8px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.transaction-success-group05 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.transaction-success-path2 {
  top: 3px;
  left: 3px;
  width: 18px;
  height: 17px;
  position: absolute;
}
.transaction-success-geometric-abstract-star2 {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.transaction-success-group06 {
  top: -5.000000058430487e-8px;
  left: -5.000000058430487e-8px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.transaction-success-group07 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.transaction-success-path3 {
  top: 3px;
  left: 3px;
  width: 18px;
  height: 17px;
  position: absolute;
}
.transaction-success-geometric-abstract-star3 {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.transaction-success-group08 {
  top: -5.000000058430487e-8px;
  left: -5.000000058430487e-8px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.transaction-success-group09 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.transaction-success-path4 {
  top: 3px;
  left: 3px;
  width: 18px;
  height: 17px;
  position: absolute;
}
.transaction-success-geometric-abstract-star4 {
  width: 24px;
  height: 24px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-shrink: 0;
}
.transaction-success-group10 {
  top: -5.000000058430487e-8px;
  left: -5.000000058430487e-8px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.transaction-success-group11 {
  top: 0px;
  left: 0px;
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-shrink: 1;
}
.transaction-success-path5 {
  top: 3px;
  left: 3px;
  width: 18px;
  height: 17px;
  position: absolute;
}
.transaction-success-frame276 {
  display: flex;
  padding: 8px;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: rgba(203, 213, 225, 1);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
.transaction-success-text08 {
  color: var(--dl-color-slate-400);
  height: auto;
  flex-grow: 1;
  text-align: left;
  line-height: 17.5px;
}
.transaction-success-frame12 {
  gap: 8px;
  display: flex;
  padding: 16px;
  overflow: hidden;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 4px;
  justify-content: center;
  background-color: var(--dl-color-brand-blueprimary);
}
.transaction-success-text10 {
  color: var(--dl-color-shades-white);
  height: auto;
  text-align: left;
  line-height: 20px;
}
.transaction-success-textarea {
  height: 96px;
  width: 98%;
  border: 1px dashed grey;
  border-radius: 10px;
  padding: 10px;
}

.track-detail-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.track-detail-frame215 {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 24px;
}

.track-detail-header {
  margin-bottom: 24px;
}

.track-detail-text {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.track-detail-order-info {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 12px;
  font-size: 14px;
  color: #666;
}

.track-detail-info-item {
  flex-basis: 48%;
  margin-bottom: 8px;
}

.track-detail-info-label {
  font-weight: bold;
  margin-right: 8px;
}

.track-detail-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
}

.track-detail-button {
  padding: 8px 16px;
  background-color: #4caf50;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  color: white;
}

.track-detail-timeline {
  position: relative;
}

.track-detail-timeline h3 {
  font-size: 16px;
  margin-bottom: 16px;
}

.track-detail-timeline-item {
  display: flex;
  margin-bottom: 24px;
  position: relative;
}

.track-detail-timeline-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ccc;
  margin-right: 16px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.track-detail-timeline-item.completed .track-detail-timeline-icon {
  background-color: #4caf50;
}

.track-detail-timeline-content h4 {
  font-size: 16px;
  margin: 0 0 4px 0;
}

.track-detail-timeline-content p {
  font-size: 14px;
  color: #666;
  margin: 0;
}

.track-detail-timeline::before {
  content: '';
  position: absolute;
  top: 40px;
  left: 11px;
  bottom: 0;
  width: 2px;
  background-color: #ccc;
}

.track-detail-link {
  margin-left: 12px;
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  text-decoration: none;
  color: #333;
  font-size: 14px;
}

.track-detail-link:hover {
  background-color: #e0e0e0;
}